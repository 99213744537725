label {
    font-weight: 400;
    font-size: 14px;
    //white-space: nowrap; // do not wrap in too short columns

    &.inlineTitle {
        margin-right: 20px;
    }

    &.inTable {
        margin: 0px;
    }

    &.input-group-addon {
        line-height: 1 !important;

        &:focus-visible {
            .theme("default", {
                border: 1px solid #21BAE7;
                box-shadow: 0 0 0 3px #BDEBF8;
                outline: none;
                position: relative;
                z-index: 5;
            });
        }
    }

    &.clickable {
        cursor: pointer;
    }

    &.disabled {
        pointer-events: none;
    }
}

.fieldset,
// for static content
fieldset {
    margin-bottom: 30px;

    h3 {
        margin-top: 0;
        margin-bottom: .75em;
        // line-height: 26px;
    }

    .checkbox {
        margin-top: 0;
    }

    &.separated {
        border-bottom: 1px solid @hr-border-modal;

        h4 {
            margin-top: 1em;
        }

        &+.fieldset:not(.separated) {}
    }

    .fieldset-actions {
        &>* {
            margin-left: .5em;
        }

        .fieldset-actions-warning {
            position: relative;
            top: 3px;
        }
    }

    &_separated_single {
        margin-bottom: 15px;
        padding: 25px 0 15px 0;

        border-top: 1px solid @hr-border-modal;
        border-bottom: 1px solid @hr-border-modal;
    }

}

.form-actions {
    &.form-actions_marginBottom {
        margin-bottom: 20px;
    }

    &.form-actions_marginTop {
        margin-top: .5em;
    }

    &.form-actions_borderTop {
        border-top: 1px solid darken(@table-border-color, 10%);
        padding-top: @line-height-computed;
    }

    &.form-actions_borderTop_bigger {
        padding-top: @line-height-computed * 2 + 2;
    }

    &>.checkbox {
        margin-top: -5px;
        margin-bottom: 15px;
    }
}

.form-actions__warning {
    position: relative;
    top: -12px;
    margin-bottom: 7px;
}

.form-group {

    input::-ms-reveal,
    input::-ms-clear {
        display: none;
    }
}

.form-group {
    &.form-group--doubleInput {
        .row>[class^='col-xs-'] {
            &:first-child {
                padding-right: 7.5px;
            }

            &:last-child {
                padding-left: 7.5px;
            }
        }
    }

    &_top {
        margin-top: 7px;
    }
}

.form-none {
    .form-group {
        margin-bottom: 0;
    }
}

mvsc-form-control2 {
    .fa.fa-info-circle.text-muted {
        margin-left: 3px;
    }

    &.hasTooltip {
        white-space: nowrap;
    }
}

.form-control {
    box-shadow: 0 0 1px transparent; // fixing safari subpixel width rendering
    -webkit-font-smoothing: antialias;
    line-height: 18px;

    &:focus {
        box-shadow: none;

        .theme("default", {
            border-color: #21BAE7;
            box-shadow: 0 0 0 3px #BDEBF8;
        });
    }

    &.form-control-static {
        padding-left: 0;
        padding-right: 0;
        border-color: transparent;
    }

    &.form-control_inline {
        display: inline-block;
        width: auto;
    }

    .hasReset & {
        padding-right: 30px;
    }

    // Reset icon
    &+.form-control-reset {
        color: @text-muted;
        position: absolute;
        bottom: 0; //(@line-height-computed + 5); // Height of the `label` and its margin
        right: 0;
        z-index: 2;
        display: block;
        width: 30px;
        height: @input-height-base;
        line-height: @input-height-base;
        text-align: center;
        opacity: .3;
        transition: opacity .5s;

        &:hover {
            opacity: 1;
            transition: opacity .5s;
        }

        .fa {
            margin-right: 0;
            font-weight: 900;
        }
    }


    .hasReset &.input-lg {
        padding-right: 47px;
    }

    &.input-lg+.form-control-reset {
        top: 0;

        width: 47px;
        height: 45px;

        .fa {
            margin-top: 14px;
            font-size: 18px;
            line-height: 18px;
        }
    }

    // for dropdown list
    .input-group.hasReset-group & + clear-link .form-control-reset {
        right: 0;
    }

    // for datepicker
    .input-group &+.form-control-reset,
    .input-group &+clear-link .form-control-reset {
        right: 35px;
    }

    &.ng-animate {
        transition: none;
    }

    &.text-muted {
        color: @color-muted;
    }

    &[readonly]:not([displayReadonlyAsDisabled]) {
        background: #fff;
        cursor: pointer;

        &.ng-select {
            pointer-events: none;
        }
    }

    &:disabled::placeholder,
    &[disabled]::placeholder {
        color: transparent;
    }
}

.mvscModal-searchField {
    padding-right: 26px;
}

clear-link .form-control-reset {
    color: @text-muted;
    position: absolute;
    bottom: 0; //(@line-height-computed + 5); // Height of the `label` and its margin
    right: 8px;
    z-index: 2;
    display: block;
    width: 30px;
    height: @input-height-base;
    line-height: @input-height-base;
    text-align: center;
    opacity: .3;
    transition: opacity .5s;

    &:hover {
        opacity: 1;
        transition: opacity .5s;
    }

    .fa {
        vertical-align: middle;
        margin-right: 0;
        margin-top: 0;
        font-size: 14px;
    }
}

//Hide x button if control is disabled.
//Important before .form-control-reset has inline style
.hasReset>.form-control[disabled]+.form-control-reset {
    display: none !important;
}

.form-control-message {
    padding-top: 7px;
}

.required {
    & {
        white-space: nowrap;
    }

    &:after {
        content: ' *';
        color: @color-status-danger;
    }
}

.inline-delete {
    position: relative;
    height: auto;
}

.inline-delete-confirmation {
    background-color: fade(@color-corp-lightGray, 95%);
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 5;

    &.inline-delete_centered {
        text-align: center;

        >.form-actions {
            height: 100%;
            margin-top: 0;

            &:before {
                content: '';
                display: inline-block;
                vertical-align: middle;
                height: 100%;
            }

            &>* {
                display: inline-block;
                vertical-align: middle;
            }
        }
    }
}

.selectWrapper {
    position: relative;

    .selectWrapper-remove {
        color: #fff;
        background-color: darken(@input-border, 10%);
        transition: background-color ease-in-out .15s;
        font-size: 10px;
        line-height: 12px;
        height: 15px;
        padding: 1px 5px;
        display: block;
        position: absolute;
        overflow: hidden;
        right: -7px;
        top: -7px;
        text-decoration: none;
        border-radius: 99px;
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
        z-index: @zindex-modal;

        .fa {
            position: relative;
            top: 1px;
            margin: -2px;
            font-size: 11px;
            line-height: 12px;
        }

        // space to the text
        .fa+* {
            margin-left: 5px;
        }
    }

    &:hover {
        .selectWrapper-remove {
            border-color: @input-border-focus;
            background-color: @color-status-danger;
        }
    }
}

.form-group {
    .checkbox {
        margin-top: 0;
    }

    &_noMargin {
        margin-bottom: 0;

        >*:last-child {
            margin-bottom: 0;
        }
    }

    &.hasTypehead {

        .dropdown-menu {
            right: 15px;
        }

        &_scroll {

            .dropdown-menu {
                max-height: 300px;
                overflow-y: auto;
                overflow-x: hidden;

                a small {
                    .ellipsis;
                }
            }
        }
    }
}

.input-radius {
    .form-control {
        border-radius: 2px;

        &:last-child,
        &:first-child {
            border-radius: 2px;
        }
    }
}

.hasTypehead_scroll {
    .dropdown-menu {
        max-height: 300px;
        overflow-y: auto;
        overflow-x: hidden;

        a small {
            .ellipsis;
        }
    }
}

.hasFullTypehead {
    .dropdown-menu {
        width: 100%;
    }

    &_min {
        .dropdown-menu {
            min-width: 100%;
        }

        .row {
            width: calc(100% + 30px);
        }
    }
}

.hasReset {
    position: relative;
}

.hasEye {
    .input-group {
        display: block;
        position: relative;

        .form-control {
            position: static;
            float: none;
            display: block;
            border-radius: 2px;
        }
    }

    .form-control[type="text"],
    .form-control[type="password"] {
        padding-right: 28px;

        &.input-lg {
            padding-right: 36px;
        }
    }

    .hasEye__link {
        position: absolute;
        top: 6px;
        right: 7px;
        z-index: 2;
        .text-muted();

        &:hover {
            .helper-link();
        }
    }

    .input-lg+.hasEye__link {
        top: 11px;
        right: 10px;
        font-size: 1.2em;
    }
}

.hasSpinner {
    .input-group {
        width: 100%;
        position: relative;

        .form-control {
            position: static;
            float: none;
            display: block;

            padding-right: 28px;
        }
    }

    .mvscSpinner {
        position: absolute;
        z-index: 5;

        font-size: 0;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
    }

    &-select {
        .mvscSpinner {
            background-color: #fff;
            padding-right: 5px;
            right: 19px;
        }
    }

    .form-control + .input-group-addon {
        border-left: none;
        margin: 0;
        width: fit-content;
    }

    .input-group-addon + .mvscSpinner {
        right: 45px;
    }

    &.input-group {
        display: flex;
        width: 100%;
    }
}

.form-horizontal {
    label.control-label {
        color: @input-color;
    }

    .form-group:not(:last-child) {
        margin-bottom: 9px;
    }
}

textarea {
    resize: vertical;
}

.textarea {
    .form-control;
    height: auto;
}

.form-inline {
    .form-group {
        margin-right: 12px;
    }

    /* Static grid for form-inline */
    .form-group[class*='form-group_sized'] {
        .form-control {
            width: 100%;
        }

        &.form-group_sized_12 {
            width: 260px;
        }

        &.form-group_sized_11 {
            width: 220px;
        }

        &.form-group_sized_10 {
            width: 210px;
        }

        &.form-group_sized_9 {
            width: 190px;
        }

        &.form-group_sized_8 {
            width: 170px;
        }

        &.form-group_sized_7 {
            width: 150px;
        }

        &.form-group_sized_6 {
            width: 130px;
        }

        &.form-group_sized_5 {
            width: 110px;
        }

        &.form-group_sized_4 {
            width: 90px;
        }

        &.form-group_sized_3 {
            width: 70px;
        }

        &.form-group_sized_2 {
            width: 50px;
        }

        &.form-group_sized_1 {
            width: 30px;
        }
    }
}

.input-group-btn {
    .dropdown-menu {
        font-size: @fontSize-base;
    }
}

.input-group_white {

    .input-group-addon {
        border: none;
        background: @white;
    }
}

.input-group_search {

    &.hasReset {
        .form-control {
            padding-right: 40px;
        }

        .form-control-reset {
            top: 20px;
            right: 6px !important;
            font-size: @fontSize-base + 5;
        }
    }

    .form-control {
        border: 1px solid @input-border;
        border-radius: 4px !important;
        display: block;
        height: 42px;
        padding-left: 38px;
        font-size: @fontSize-base + 2;
    }

    .input-group-addon {
        font-size: @fontSize-base + 5;
    }

    .fa-search {
        border-radius: 6px;
        color: @pre-border-color;
        position: absolute;
        height: 40px;
        width: 38px;
        line-height: 28px;
        z-index: 5;
        left: 1px;
    }
}

sub-fieldset {
    display: block;
}

sub-fieldset:before,
sub-fieldset:after {
    content: '';
    display: block;
    clear: both;
}

.radio,
.checkbox,
.radio-inline,
.checkbox-inline {
    padding-left: 0;
    cursor: default;
    line-height: 18px;

    &.input-sm>input+label {
        font-size: @font-size-small;
    }

    input[type="checkbox"],
    input[type="radio"] {
        border: none;
        -moz-appearance: none;
        opacity: 0;
        width: 0;
        height: 0;

        &:not(:disabled) {

            &:checked:hover {

                &+label:before {
                    .theme("default", {
                        border-color: darken(@themeBrand-color-corp-skyBlue, 10%);
                    });

                    .theme("black", {
                        border-color: darken(@themeBlack-color-corp-skyBlue, 10%);
                    });

                    .theme("classic", {
                        border-color: darken(@themeClassic-color-corp-skyBlue, 10%);
                    });
                }
            }

            &:checked:focus {

                .theme("default", {
                    border-color: #21BAE7;
                    box-shadow: 0 0 0 3px #BDEBF8;
                });

                .theme("black", {
                    border-color: darken(@themeBlack-color-corp-skyBlue, 10%);
                });

                .theme("classic", {
                    border-color: darken(@themeClassic-color-corp-skyBlue, 10%);
                });
            }
        }

}

input {

    +label,
    ~label {
        position: relative;
        display: inline-block;
        margin-bottom: 0;
        padding-left: 22px;
        line-height: 1.43;
        line-height: 20px;

        color: @color-base;
        cursor: pointer;

        &:empty {
            height: 15px;
        }

        &:before {
            position: absolute;
            top: .72em;
            display: inline-block;
            content: "";
            border: 1px solid @input-border;
            width: 14px;
            height: 14px;
            left: 0;
            margin-top: -7px;

            box-sizing: border-box;
            background-color: @input-bg;
            transition: background-color 0.3s;
            box-shadow: 0 0 1px transparent; // fixing safari subpixel width rendering
            border-radius: 2px;
        }

        &:after {
            position: absolute;
            display: inline-block;
            line-height: 1;
        }

        &:hover {
            &:before {
                border-color: lighten(@input-border-focus, 7%);
            }
        }

    }

    &:focus+label:before {
        border-color: lighten(@input-border-focus, 7%);

        .theme("default", {
            border-color: #21BAE7;
            box-shadow: 0 0 0 3px #BDEBF8;
        });
    }
}

// common styles
input:checked+label:after,
input:checked~label:after {
    animation: checkboxRadioAnimation 0.2s;
}

input:checked+label:before,
input:checked~label:before {
    .theme("default", {
        background: @themeBrand-color-corp-skyBlue;
        border-color: @themeBrand-color-corp-skyBlue;
    });

.theme("black", {
    background: @themeBlack-color-corp-skyBlue;
    border-color: @themeBlack-color-corp-skyBlue;
});

.theme("classic", {
    background: @themeClassic-color-corp-skyBlue;
    border-color: @themeClassic-color-corp-skyBlue;
});
}

input:not(:checked)+label:before {
    transition: background 0.3s;
}

// fade colors on disabled controls
input[disabled],
input[disabled]:checked {
    &+label {
        cursor: default;
    }

    &+label:before {
        border-color: @input-border;
        background-color: @input-bg-disabled;
    }

    &[type="radio"]+label:after {
        background-color: @input-border-focus;
    }

    &+label:active,
    &+label:focus {
        &:before {
            background-color: lighten(@input-border, 5%);
        }
    }
}
}

.radio-group-required {
    &::after {
        content: '*';
        color: #D7262D;
        margin-left: 10px;
    }
}

.radio--fix-order-inventory {
    margin-top: 6px;
    white-space: nowrap;
}

.radio,
.radio-inline {
    input+label {
        &:before {
            width: 16px;
            height: 16px;
            border-radius: 100%;
        }
    }

    input+label:before {
        top: .65em;
    }

    input+label:after {
        content: "";
        margin-top: -2px;
        width: 6px;
        height: 6px;
        left: 5px;
        top: .65em;
        background: @color-white;
        border-radius: 100%;
    }

    input:checked+label:after {
        border-radius: 100%;
    }

    input:checked:focus+label:before {
        .theme("default", {
            border-color: @themeBrand-color-corp-skyBlue;
        });

    .theme("black", {
        border-color: @themeBlack-color-corp-skyBlue;
    });

.theme("classic", {
    border-color: @themeClassic-color-corp-skyBlue;
});
}

input:not(:checked)+label:after {
    transform: scale(0);
    transition: transform 0.3s;
}
}

.checkbox,
.checkbox-inline {
    input+label.text-smallest {
        padding-top: 1px;
    }

    input+label:after {
        left: 2px;
        top: .72em;
        margin-top: -4px;
        width: 10px;
        height: 8px;
        content: '';
        .retina-img('@{assets-path}img/check-sprite.png', '@{assets-path}img/check-sprite_2x.png', 10px, 16px);
    }

    input[disabled]+label,
    input[disabled]:checked+label {
        &:after {
            background-position: 0 -8px;
        }
    }

    input:not(:checked)+label:after {
        transform: scale(0);
        transition: transform 0.2s;
    }

    &.checkbox_asp {
        input:not(:checked)+label:after {
            transform: none;
            transition: none;
        }
    }
}

.radio-inline+.form-control,
.checkbox-inline+.form-control {
    margin-left: 10px;
}

.checkbox-inline+.pseudoLink {
    margin-left: 10px;
    bottom: -2px;
}

/* Checkbox-button */
.checkbox.checkbox_button {
    text-align: center;

    input:not([disabled])+label:hover {
        background: darken(@well-bg, 4%);
        transition: none;
    }

    input:checked+label span:before {
        position: absolute;
        left: -19px;
        top: .4em;
        width: 10px;
        height: 8px;
        content: '';
        .retina-img('@{assets-path}img/check-sprite.png', '@{assets-path}img/check-sprite_2x.png', 10px, 16px);
    }

    input:disabled:checked+label span:before {
        background-position: 0 -8px;
    }

    input:disabled+label,
    input:disabled:checked+label {
        border-color: @input-border;
    }

    label {
        display: block;
        padding: 20px 15px 19px 15px;
        border: 1px solid #afb7bf;
        border-radius: @border-radius-base;
        background-color: @well-bg;
        transition: background-color .3s;

        &:before {
            position: relative;
            margin-right: 7px;
            top: .15em;
        }

        span {
            display: inline-block;
            position: relative;
        }

        &:after {
            content: none;
        }
    }

    input:checked+label {
        border-color: #30954a;
    }
}

/* radio-button */
.radio.radio_button {
    text-align: center;
    display: inline-block;
    width: 100px;

    &:not(:last-child) {
        margin-right: 7px;
    }

    input:not([disabled])+label:hover {
        background: darken(@well-bg, 4%);
        transition: none;
    }

    input:checked+label span:before {
        position: absolute;
        left: -19px;
        top: .4em;
        width: 10px;
        height: 8px;
        content: '';

    }

    input:checked+label:after {
        top: 21px;
        left: 50%;
        margin-left: -3px;
    }


    input:disabled:checked+label span:before {
        background-position: 0 -8px;
    }

    input:disabled+label,
    input:disabled:checked+label {
        border-color: @input-border;
    }

    label {
        display: block;
        padding: 13px 15px 9px 15px;
        border: 1px solid #afb7bf;
        border-radius: @border-radius-base;
        background-color: @well-bg;
        transition: background-color .3s;

        &:before {
            position: relative;
            top: .15em;
        }

        span {
            display: inline-block;
            position: relative;
        }
    }

    input:checked+label {
        border-color: #30954a;
    }

    .radio-button__title {
        font-size: 22px;
        margin-top: 5px;
    }

    &_service {

        width: 100%;

        label {
            display: block;
            width: 100%;
            background-color: #fff;
            border: 1px solid #E6E6E6;
            font-size: 15px;
            line-height: 18px;
            min-height: 190px;
            text-align: center;
            padding: 122px 10px 14px 10px !important;
            color: #296189;

            &:after,
            &:before {
                content: none;
            }
        }

        input:checked+label,
        label:hover {
            border-color: @color-status-info;
            background-color: #F2FBFF !important;
        }

        &_vitu_50_choice {

            label {
                background: url('@{assets-path}img/svg/icon-usa.svg') top 27px center no-repeat #fff;
            }

            input:checked+label,
            label:hover {
                background: url('@{assets-path}img/svg/icon-usa_blue.svg') top 27px center no-repeat #F2FBFF !important;
            }
        }

        &_il_ert {

            label {
                background: url('@{assets-path}img/svg/icon-il.svg') top 27px center no-repeat #fff;
            }


            input:checked+label,
            label:hover {
                background: url('@{assets-path}img/svg/icon-il_blue.svg') top 27px center no-repeat #F2FBFF !important;
            }
        }

        &_ca_bpevr,
        &_ca_rs_and_temp_tag {

            label {
                background: url('@{assets-path}img/svg/icon-ca.svg') top 27px center no-repeat #fff;
            }


            input:checked+label,
            label:hover {
                background: url('@{assets-path}img/svg/icon-ca_blue.svg') top 27px center no-repeat #F2FBFF !important;
            }
        }

        &_or_evr {

            label {
                background: url('@{assets-path}img/svg/icon-or.svg') top 27px center no-repeat #fff;
            }


            input:checked+label,
            label:hover {
                background: url('@{assets-path}img/svg/icon-or_blue.svg') top 27px center no-repeat #F2FBFF !important;
            }
        }

        &_va_evr {

            label {
                background: url('@{assets-path}img/svg/icon-virginia.svg') top 27px center no-repeat #fff;
            }


            input:checked+label,
            label:hover {
                background: url('@{assets-path}img/svg/icon-virginia_blue.svg') top 27px center no-repeat #F2FBFF !important;
            }
        }

    }

}

.radio.radio_extra {
    margin-top: 0;
    margin-bottom: 0;
    padding-right: 5px;
    padding-left: 5px;
    height: 100%;

    label {
        display: block;
        padding: 17px 20px 5px 42px;
        color: #333;
        z-index: 1;
        font-size: 13px;

        p {
            margin-top: 10px;
        }
    }

    .readMore {
        color: #333;
        font-weight: 400;
    }

    .readMore.readMore_collapsed {
        margin-bottom: 20px;
        padding-bottom: 30px;
        color: @color-muted;
        height: 45px;

        &:after {
            background: #F4F5F6;
            height: 25px;
            bottom: 0;
            right: 1px;
            left: 1px;

        }

        .readMore-trigger {
            left: 42px;
            transform: none;
        }

    }

    .readMore-wrapper {
        padding: 0 20px 20px 42px;
        font-size: 13px;

    }

    .readMore:before {
        content: none;
    }

    .radio_extra__back {
        position: absolute;
        top: 0;
        left: 5px;
        right: 5px;
        bottom: 0;
        background: #F4F5F6;
        border: 1px solid #AFB7BF;
        border-radius: 2px;
        z-index: 0;
    }

    input:checked {
        +label+.radio_extra__back {
            border-color: #30954A;
        }
    }

    input+label:before {
        top: 27px;
        left: 20px;
    }

    input+label:after {
        top: 27px;
        left: 25px;
    }
}

.form-group_table {
    display: table;

    .row {
        display: table-row;

        .radio_extra {
            position: relative;
            display: table-cell !important;
        }
    }
}

/* New checkboxRadio styles */
.checkboxRadio {
    position: relative;
    display: block;

    &:not(.checkboxRadio_colored):not(.checkboxRadio_bordered) {

        input:not([disabled]):not(:checked)+label {

            &:hover:before {
                border-color: transparent;
                background: lighten(@input-border-focus, 7%);
            }
        }
    }

    input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
    }

    input+label {
        position: relative;
        margin-bottom: 3px;
        padding: 0 0 0 19px;
        cursor: pointer;

        &:empty {
            height: 15px;
        }

        &:before {
            position: absolute;
            left: 3px;
            top: .47em;
            content: "";
            border: 1px solid transparent;
            background-color: @input-border;
            width: 6px;
            height: 6px;
            border-radius: 50%;
        }


        &:focus {
            &:before {
                background-color: @input-border-focus;
            }
        }
    }

    input:focus+label:before {
        border: none;
        background: @color-muted;
    }

    input:checked+label {
        &:before {
            border: none;
            background: none;
        }

        &:hover:before {
            background: none;
        }

        &:after {
            position: absolute;
            left: 0px;
            top: 2px;
            font-size: @fontSize-base + 1;
            content: '\f00c';
            color: @color-status-success;
            font-family: "Font Awesome 5 Pro";
            font-size: @fontSize-base + 1;
            line-height: 1;
            background: none;
        }
    }

    input[disabled] {

        &+label {
            cursor: default;
        }

        &+label:after {
            opacity: .5;
        }

        &+label:before {
            background-color: @input-bg-disabled;
        }

        &:checked+label:before {
            background: none;
        }
    }

    &_inline {
        display: inline-block;
        vertical-align: top;

        &:not(:first-child) {
            margin-left: 10px;
        }
    }
}

.form-group.has-error {
    .help-block {
        margin-bottom: 0;
    }
}

/* Checkbox + Radio — support has-error style */
.has-error {

    .radio,
    .checkbox,
    .radio-inline,
    .checkbox-inline {
        input+label {
            color: @state-danger-text;

            &:before {
                border-color: @state-danger-text;
                background-color: @state-danger-bg;
            }
        }
    }
}

.radio.has-error,
.checkbox.has-error,
.radio-inline.has-error,
.checkbox-inline.has-error {
    input+label {
        color: @state-danger-text;

        &:before {
            border-color: @state-danger-text;
            background-color: @state-danger-bg;
        }
    }
}

// SWITCH
.switch {
    display: inline-block;
    vertical-align: middle;
    user-select: none;

    input {
        display: none;
    }

    label {
        display: block;
        position: relative;
        padding-left: 62px;
        cursor: pointer;

        &.switch-text {
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
            color: #000;
            text-transform: none;
            margin-bottom: 0;
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 50px;
            height: 24px;
            border-radius: 40px;
            background: #dedede;
            transition: .3s ease;
        }

        &::after {
            content: "";
            width: 20px;
            height: 20px;
            position: absolute;
            top: 2px;
            left: 2px;
            border: none;
            background: #fff;
            box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
            border-radius: 50%;
            transition: .3s ease;
        }
    }

    input {
        &:disabled {
            ~label {
                cursor: default;
            }
        }

        &:checked {
            ~label {
                &::before {
                    background: #2a9647;
                }

                &::after {
                    left: 28px;
                }
            }
        }
    }
}

/* Colored mode */
.checkboxRadio_colored {

    input+label,
    input:focus+label {

        &:before {
            width: 7px;
            height: 7px;
            border: 1px solid @color-muted;
            background-color: @color-white;
        }
    }

    input:checked+label:before {
        content: none;
    }

    input[disabled]+label:before {
        opacity: .5;
    }

    &:not(.checkboxRadio_bordered) {

        input+label:hover:before,
        input:focus+label:before {
            background-color: @color-muted;
        }
    }
}

/* bordered mode */
.checkboxRadio_bordered {

    &.checkboxRadio_inline {
        margin: 0 1px 1px 0;

        label {
            margin-bottom: 0;
        }
    }

    input+label:empty {
        display: block;
        width: 24px;
        height: 24px;
        border: 1px solid transparent;
        border-radius: 2px;

        &:before {
            width: 8px;
            height: 8px;
            left: 7px;
            top: 7px;
            border: 1px solid @color-muted;
            background-color: @color-white; //needed repeat
        }

        &:hover {
            border-color: @input-border;
        }
    }

    input:checked+label,
    input:checked:focus+label,
    input:checked:hover+label,
    input[disabled]:checked:hover+label {
        border: 1px solid;

        .theme("default", {
            border-color: @themeBrand-color-corp-skyBlue;
        });

    .theme("black", {
        border-color: @themeBlack-color-corp-skyBlue;
    });

.theme("classic", {
    border-color: @themeClassic-color-corp-skyBlue;
});

&:after {
    content: none;
}

&:before {
    content: '';
}
}

input:focus+label {
    border-color: @input-border;
}

input[disabled]+label:hover {
    border-color: transparent;
}
}

/* Colors */
.checkboxRadio_colored {

    &.checkboxRadio_colored_blue input {

        &+label:before,
        &+label:hover:before {
            background: @color-pastel_blue;
            border: 1px solid @color-pastel-border_blue;
        }
    }

    &.checkboxRadio_colored_green input {

        &+label:before,
        &+label:hover:before {
            background: @color-pastel_green;
            border: 1px solid @color-pastel-border_green;
        }
    }

    &.checkboxRadio_colored_violet input {

        &+label:before,
        &+label:hover:before {
            background: @color-pastel_violet;
            border: 1px solid @color-pastel-border_violet;
        }
    }

    &.checkboxRadio_colored_turquoise input {

        &+label:before,
        &+label:hover:before {
            background: @color-pastel_turquoise;
            border: 1px solid @color-pastel-border_turquoise;
        }
    }

    &.checkboxRadio_colored_yellow input {

        &+label:before,
        &+label:hover:before {
            background: @color-pastel_yellow;
            border: 1px solid @color-pastel-border_yellow;
        }
    }

    &.checkboxRadio_colored_orange input {

        &+label:before,
        &+label:hover:before {
            background: @color-pastel_orange;
            border: 1px solid @color-pastel-border_orange;
        }
    }

    &.checkboxRadio_colored_red input {

        &+label:before,
        &+label:hover:before {
            background: @color-pastel_red;
            border: 1px solid @color-pastel-border_red;
        }
    }

    &:not(.checkboxRadio_bordered) {

        &.checkboxRadio_colored_blue input {
            &:not([disabled]) {

                &:focus,
                &:hover {

                    &+label:before {
                        border: 1px solid @color-pastel-border_blue;
                        background: @color-pastel-border_blue;
                    }
                }
            }
        }

        &.checkboxRadio_colored_green input {
            &:not([disabled]) {

                &:focus,
                &:hover {

                    &+label:before {
                        border: 1px solid @color-pastel-border_green;
                        background: @color-pastel-border_green;
                    }
                }
            }
        }

        &.checkboxRadio_colored_violet input {
            &:not([disabled]) {

                &:focus,
                &:hover {

                    &+label:before {
                        border: 1px solid @color-pastel-border_violet;
                        background: @color-pastel-border_violet;
                    }
                }
            }
        }

        &.checkboxRadio_colored_turquoise input {
            &:not([disabled]) {

                &:focus,
                &:hover {

                    &+label:before {
                        border: 1px solid @color-pastel-border_turquoise;
                        background: @color-pastel-border_turquoise;
                    }
                }
            }
        }

        &.checkboxRadio_colored_yellow input {
            &:not([disabled]) {

                &:focus,
                &:hover {

                    &+label:before {
                        border: 1px solid @color-pastel-border_yellow;
                        background: @color-pastel-border_yellow;
                    }
                }
            }
        }

        &.checkboxRadio_colored_orange input {
            &:not([disabled]) {

                &:focus,
                &:hover {

                    &+label:before {
                        border: 1px solid @color-pastel-border_orange;
                        background: @color-pastel-border_orange;
                    }
                }
            }
        }

        &.checkboxRadio_colored_red input {
            &:not([disabled]) {

                &:focus,
                &:hover {

                    &+label:before {
                        border: 1px solid @color-pastel-border_red;
                        background: @color-pastel-border_red;
                    }
                }
            }
        }
    }
}

// supports infinite cols in a single row
// used as a mixin in _efileForm.less
.row.infiniteCols {

    &,
    ng-include {
        font-size: 0;
    }

    >*,
    ng-include>* {
        font-size: @fontSize-base;
    }

    h4 {
        font-size: 17px;
    }

    h4 {
        margin-left: 15px;
        margin-right: 15px;
        line-height: 18px;

        &.fees-title-section {
            margin-left: 0;
            margin-right: 0;
        }
    }

    sub-fieldset {
        margin-left: 0;
        margin-right: 0;
        font-size: 0;

        >* {
            font-size: @fontSize-base;
        }
    }

    [class^="col-xs"],
    [class*=" col-xs"] {
        float: none;
        display: inline-block;
        vertical-align: top;
        font-size: @fontSize-base;
    }
}


/* Remove input number arrows */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    appearance: none;
}

input[type=number] {
    -moz-appearance: textfield;
}

/* /Remove input number arrows */


.reports-fix {
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
        white-space: normal;
    }

    .form-control.ng-select .ng-arrow-wrapper {
        width: 17px;
        margin-left: 7px;
    }

    .ng-select .ng-arrow-wrapper .ng-arrow {
        top: 1.5px;
    }

    .ng-select.ng-select-opened>.ng-select-container .ng-arrow {
        top: -1.5px;
    }

    clear-link .form-control-reset {
        line-height: 34px;
    }

    .fa.fa-warning {
        margin-top: 0;
        margin-left: 7px;
        margin-bottom: 2px;
    }

    #reportName {
        width: 260px;
    }

    .btn .fa:only-child.fa-mvsc-plus {
        top: 2px;
        font-weight: normal;
    }
}

.ng-select#emailList {
    .ng-select-container {
        height: 31px;
        min-height: 31px;
        line-height: 0;

        .ng-value-container {
            align-items: flex-start;
            padding: 0;
            min-height: 29px;

            .ng-value {
                font-size: 13px;
                line-height: 0;
                background-color: #eee;
                border: 1px solid #D8D8D8;
                margin: 4px 1px 0 4px;
                cursor: default;

                &-label {
                    line-height: 17px;
                }

                &-icon {
                    float: right;
                    border-right: none !important;
                    font-size: 0;
                    line-height: 0;
                    padding-bottom: 0;

                    &::before {
                        content: "\f00d";
                        font-family: "Font Awesome 5 Pro";
                        font-weight: normal;
                        font-size: 11px;
                        line-height: 18px;
                    }

                    &:hover {
                        background: none;
                    }
                }

                &:hover {
                    border-color: #999;
                }
            }

            .ng-input {
                height: 29px;
                display: flex;
                align-items: center;
                padding: 0;

                & > input {
                    padding-left: 10px;
                    margin: 0;

                    &:hover {
                        cursor: text;
                    }
                }
            }

            .ng-placeholder {
                top: 6px;
                line-height: 18px;
                padding-left: 10px;
            }
        }

        &:hover {
            cursor: text;
        }

        &.ng-has-value {
            background: #fff !important;
        }
    }

    &.ng-select-bottom {
        .ng-value-container {
            .ng-placeholder {
                padding-left: 8px;
            }
        }
    }

    &.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
        border-color: #D8D8D8;
        box-shadow: none !important;
    }

    .ng-clear-wrapper,
    .ng-arrow-wrapper,
    .ng-select-container:after {
        display: none;
    }

    // select dropdown
    .ng-dropdown-panel {
        .ng-dropdown-panel-items {
            .ng-option {
                span {
                    font-weight: normal !important;
                }

                &.ng-option {
                    &-selected {
                        background: none;
                        color: #202020;
                    }

                    &-marked {
                        background: #eee;
                    }
                }
            }
        }
    }
}

.address-pin {
  display: flex;
  margin-top: 5px;
  div {
    font-size: 12px;
    line-height: 1.4;
    margin-left: 5px;
  }
}

.ng-select.ng-select-disabled * {
    cursor: not-allowed !important;
}
